import React, { useState } from 'react';
import AppBarMui from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { menuIcon } from 'src/assets';
import { links, mobileBreakpoint } from 'src/constansts';
import { colors } from 'src/utils/colors';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography/Typography';

type Link = {
  title: string;
  path: string
}

const AppBar = styled(AppBarMui)`
  background: ${colors.lightGray};
  color: ${colors.darkGray};
  display: inline;
  top: auto;
  bottom: 0;
  height: 54px;

  @media (min-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

const Button = styled(ButtonMui)`
  color: ${colors.darkGray};
  font-weight: bold;
  letter-spacing: 1px;
  width: 200px;
  padding: 15px 0;
`;

const Name = styled(Typography)`
  font-family: 'Poppins', sans-serif;
`;

export const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton onClick={toggleMenu}>
          <img src={menuIcon} alt="menu-icon" width="30" />
        </IconButton>
        <div style={{ width: '75%' }}>
          <Name variant="h6" align="center">
            Paulo Marcenaria
          </Name>
        </div>
        <Drawer open={isOpen} onClose={toggleMenu}>
          <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
            <Grid item style={{ marginTop: '20%' }}>
              <Name variant="h6" align="center">
                Paulo Marcenaria
              </Name>
            </Grid>
            <Grid item xs="auto" style={{ display: 'grid' }}>
              {links.map((link: Link) => (
                <a href={link.path} key={link.path} style={{ textDecoration: 'none' }}>
                  <Button color="inherit" onClick={() => toggleMenu()}>{link.title}</Button>
                </a>
              ))}
            </Grid>
          </Grid>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};
