import React from 'react';
import { wppIcon } from 'src/assets';
import { WppIcon, Button } from './ButtonStyle';

export const WppButton = () => (
  <a
    style={{ textDecoration: 'none' }}
    href="//api.whatsapp.com/send?phone=5561981052995&text=Olá Paulo, gostaria de pedir um orçamento sobre:"
  >
    <Button
      variant="contained"
      size="large"
      startIcon={<WppIcon src={wppIcon} alt="wpp-icon" />}
    >
      Orçamento pelo Wpp
    </Button>
  </a>
);
