import React from 'react';
import Typography from '@material-ui/core/Typography';
import { woodBackground } from 'src/assets';
import styled from 'styled-components';
import { colors } from 'src/utils/colors';
import { mobileBreakpoint } from 'src/constansts';

const Container = styled.div`
  position: relative;
  max-height: 350px;
  margin-bottom: 5%;
`;

const ImgContent = styled.div`
  top: 0;
  position: absolute;
  background: rgba(78, 56, 14, 0.5);
  height: calc(100% - 3px);
  display: flex;
  padding: 0 20%;
  @media (max-width: ${mobileBreakpoint}px) {
    padding: 0 5%;
  }
`;

const Img = styled.img`
  width: 100%;
  max-height: 350px;
`;

const Text = styled(Typography)`
  color: #fff;
  letter-spacing: 1px;
  margin: auto;
  font-weight: bold;
  text-shadow: 1px 1px ${colors.darkGray};
`;

export const About = () => {
  return (
    <Container id="quem-somos">
      <Img src={woodBackground} alt="wood-background-image" />
      <ImgContent>
        <Text variant="body1" align="center">
          A paulo marcenaria é uma empresa com mais de 18 anos de atuação no Distrito Federal, sempre entregando qualidade nos móveis planejados.
        </Text>
      </ImgContent>
    </Container>
  );
};
