import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid/Grid';
import DividerMui from '@material-ui/core/Divider';
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { closeIcon } from 'src/assets';
import { theme } from 'src/utils/theme';
import { Image, s3Url } from 'src/constansts';

const CloseIcon = styled.img`
  width: 2rem;
`;

const Divider = styled(DividerMui)`
  margin: 2% 0 0;
`;

type Props = {
  open: boolean;
  onClose: () => void;
  selectedImageIndex: number;
  images: Image[];
}

export const GalleryModal = ({ open, onClose, selectedImageIndex, images }: Props) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <Grid container justify="flex-end" alignItems="center">
        <Grid item xs="auto">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon src={closeIcon} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Carousel
        showThumbs={false}
        swipeable
        emulateTouch
        selectedItem={selectedImageIndex}
        infiniteLoop
        autoPlay
      >
        {images.map(({ link }: Image) => (
          <img
            style={{ maxHeight: '85vh', maxWidth: '100%' }}
            src={`${s3Url}${link}`}
          />
        ))}
      </Carousel>
    </Dialog>
  );
};
