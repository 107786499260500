import ButtonMui from '@material-ui/core/Button/Button';
import styled from 'styled-components';
import { colors } from 'src/utils/colors';

export const WppIcon = styled.img`
  width: 2rem;
`;

export const Button = styled(ButtonMui)`
  background: #fff;
  color: ${colors.darkGray};
  min-width: 260px;
`;
