import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { WppButton } from './WppButton';
import { PhoneButton } from './PhoneButton';
import { Title } from './Title';
import { colors } from 'src/utils/colors';

const Container = styled.div`
  margin-top: 5px;
  background-color: ${colors.yellow};
  padding: 5% 10%;
  text-align: center;
`;

export const Contact = () => {
  return (
    <Container id="contato">
      <Title title="Contato" color={colors.darkGray} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <WppButton />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneButton />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Email</Typography>
          <Typography variant="h6">paulomarcenariasobmedida@gmail.com</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
