import React from 'react';
import styled from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import { Header, Home, MobileHeader } from 'src/components';
import './App.css';
import { mobileBreakpoint } from './constansts';

const Container = styled.div`
  @media (max-width: ${mobileBreakpoint}px) {
    margin-bottom: 54px;
  }
`;

export const App = () => {
  return (
    <StylesProvider injectFirst>
      <Container>
        <Header />
        <MobileHeader />
        <Home />
      </Container>
    </StylesProvider>
  );
};
