import React from 'react';
import { phoneIcon } from 'src/assets';
import { WppIcon, Button } from './ButtonStyle';

export const PhoneButton = () => (
  <a href="tel:61981052995" style={{ textDecoration: 'none' }}>
    <Button
      variant="contained"
      size="large"
      startIcon={<WppIcon src={phoneIcon} alt="phone-icon" />}
    >
      Ou ligue (61) 98105-2995
    </Button>
  </a>
);
