import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import ButtonMui from '@material-ui/core/Button';
import { fronImg } from 'src/assets';
import { mobileBreakpoint } from 'src/constansts';
import { colors } from 'src/utils/colors';
import { theme } from 'src/utils/theme';
import { Services } from './Services';
import { About } from './About';
import { Gallery } from './Gallery';
import { Contact } from './Contact';
import { Footer } from './Footer';

const Container = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 64px;
  @media (max-width: ${mobileBreakpoint}px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: -7%;
  @media (max-width: ${mobileBreakpoint}px) {
  margin-top: 0;
  }
`;

const ImgContent = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  height: calc(100% - 3px);
  background: rgba(170, 216, 211, 0.25);
`;

const FrontImg = styled.img`
  width: 100%;
`;

const Content = styled.div`
  min-width: 90vw;
  text-align: center;
  position: absolute;
  z-index: 20;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled(Typography)`
  color: #fff;
  text-shadow: 3px 2px ${colors.darkGray};
`;

const SubTitle = styled(Typography)`
  color: #fff;
  text-shadow: 2px 2px ${colors.darkGray};
  letter-spacing: 1px;
`;

const Button = styled(ButtonMui)`
  background: ${colors.blue};
  border: 1px solid #fff;
  color: #fff;
  text-shadow: 0.5px 0.5px ${colors.darkGray};
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-top: 5%;
  &:hover {
    background: ${colors.lightBlue};
    color: ${colors.darkGray};
  text-shadow: 0.5px 0.5px #fff;

  }
`;

export const Home = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <div>
          <ImgContainer>
            <FrontImg src={fronImg} alt="front-img" />
            <ImgContent>
              <Content>
                <Title variant="h3">
                  Paulo Marcenaria
                </Title>
                <SubTitle variant="h6">
                  Mais de 18 anos de atuação no DF
                </SubTitle>
                <a
                  style={{ textDecoration: 'none' }}
                  href="//api.whatsapp.com/send?phone=5561981052995&text=Olá Paulo, gostaria de pedir um orçamento sobre:"
                >
                  <Button variant="outlined" size="large">
                    Faça um orçamento
                  </Button>
s                </a>
              </Content>
            </ImgContent>
          </ImgContainer>

          <Services />
          <About />
          <Gallery />
          <Contact />
          <Footer />
        </div>
      </Container>
    </ThemeProvider>
  );
};
