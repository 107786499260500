import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import GridListTileMui from '@material-ui/core/GridListTile';
import { useWindowSize } from 'src/hooks';
import {
  mobileBreakpoint, s3Url, imagesMobile, imagesWeb, Image,
} from 'src/constansts';
import { GalleryModal } from './GalleryModal';
import { Title } from './Title';
import { colors } from 'src/utils/colors';

const Container = styled.div`
`;

const GridListTile = styled(GridListTileMui)`
  cursor: pointer;
`;

export const Gallery = () => {
  const [width, height] = useWindowSize();
  const [images, setImages] = useState(imagesMobile);
  const [gridCols, setGridCols] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    if (width < mobileBreakpoint) {
      setImages(imagesMobile);
      setGridCols(1)
      ;

      return;
    }

    setImages(imagesWeb);
    setGridCols(3);
  }, [height, width]);


  const onOpenModal = (index: number) => {
    setSelectedImage(index);
    setIsModalOpen(true);
  };

  return (
    <Container id="galeria">
      <GalleryModal
        open={isModalOpen}
        selectedImageIndex={selectedImage}
        images={images}
        onClose={() => setIsModalOpen(false)}
      />
      <Title title="Galeria" color={colors.darkGray} />
      <GridList cellHeight={160} cols={gridCols}>
        {images.map(({ link, cols, rows }: Image, index: number) => (
          <GridListTile
            key={link}
            cols={cols || 1}
            rows={rows}
          >
            <img src={`${s3Url}${link}`} alt={link} onClick={() => onOpenModal(index)} />
          </GridListTile>
        ))}
      </GridList>
    </Container>
  );
};
