import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography/Typography';

type Props = {
  title: string;
  color?: any;
}

type StyledProps = TypographyProps & {
  textcolor?: any;
};

const Text = styled(Typography) < StyledProps > `
  color: ${props => props.textcolor || '#fff'};
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 5%;
`;

export const Title = ({ title, color = '#fff' }: Props) => (
  <Text variant="h5" align="center" textcolor={color}>
    {title}
  </Text>
);
