import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  axeIcon, hammerIcon, phoneIcon, screwdriverIcon, wppIcon,
} from 'src/assets';
import { colors } from 'src/utils/colors';
import { PhoneButton } from './PhoneButton';
import { WppButton } from './WppButton';
import { Title } from './Title';

type Service = {
  title: string;
  description: string;
  icon: any;
}

const Container = styled.div`
  background: #007b7f;
  margin-top: -4px;
  padding: 5%;
`;

const ServiceDescription = styled(Typography)`
  margin-top: 1%;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  width: 1.3rem;
  margin-right: 1.5%;
`;

const WppContainer = styled.div`
  margin-top: 5%;
  width: 100%;
  text-align: center;
`;

const services = [
  {
    title: 'Marcenaria',
    description: 'Confecção de móveis planejados,  cozinhas, quartos, closets, camas, armários em geral, mesas.',
    icon: screwdriverIcon,
  },
  {
    title: 'Reparos',
    description: 'Adicionar novas peças em móveis, consertar portas, trocar dobradiças e fechaduras danificadas.',
    icon: hammerIcon,
  },
  {
    title: 'Manutenção',
    description: 'Restauração de cadeiras, portas e tudo que for posível restaurar.',
    icon: axeIcon,
  },
];

export const Services = () => {
  return (
    <Container id="servicos">
      <Title title="Nossos Serviços" />
      <Grid container style={{ color: '#fff' }} spacing={5}>
        {services.map((service: Service) => (
          <Grid item xs={12} md={4} key={service.title}>
            <IconContainer>
              <Icon src={service.icon} alt={service.title} />
              <Typography variant="h5" align="center">
                {service.title}
              </Typography>
            </IconContainer>
            <ServiceDescription variant="body1" align="center">
              {service.description}
            </ServiceDescription>
          </Grid>
        ))}
      </Grid>

      <WppContainer>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <WppButton />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneButton />
          </Grid>
        </Grid>
      </WppContainer>
    </Container>
  );
};
