import styled from 'styled-components';
import AppBarMui from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { links, mobileBreakpoint } from 'src/constansts';
import { colors } from 'src/utils/colors';

const AppBar = styled(AppBarMui)`
  background: ${colors.darkGray};
  color: #fff;
  padding: 0 30px;
  display: inline;
  height: 64px;

  @media (max-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;

const Button = styled(ButtonMui)`
  color: #fff;
  font-weight: bold;
  letter-spacing: 2px;
  margin-right: 10px;
`;

const Name = styled(Typography)`
  font-family: 'Poppins', sans-serif;
`;

const PhoneNumber = styled(Typography)`
  font-weight: bold;
  color: ${colors.darkYellow};
  cursor: pointer;
`;

type Link = {
  title: string;
  path: string
}

export const Header = () => {
  return (
    <AppBar>
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs="auto">
            <Name variant="h6">
              Paulo Marcenaria
            </Name>
          </Grid>
          <Grid item xs="auto">
            {links.map((link: Link) => (
              <a href={link.path} key={link.path} style={{ textDecoration: 'none' }}>
                <Button color="inherit">{link.title}</Button>
              </a>
            ))}
          </Grid>
          <Grid>
            <a href="tel:61981052995" style={{ textDecoration: 'none' }}>
              <PhoneNumber variant="h6">
              (61) 98105-2995
              </PhoneNumber>
            </a>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
