import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { colors } from 'src/utils/colors';
import styled from 'styled-components';

const Container = styled.div`
  background: ${colors.darkGray};
  padding: 5%;
`;

const Text = styled(Typography)`
  color: #fff;
`;

export const Footer = () => (
  <Container>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Text>
          © 2021 Paulo Marcenaria
        </Text>
      </Grid>
      <Grid item xs={12} md={8}>
        <Text>
          Todos os direitos reservados a Paulo Marcenaria
        </Text>
      </Grid>
    </Grid>
  </Container>
);
