export const links = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Serviços',
    path: '/#servicos',
  },
  {
    title: 'Galeria',
    path: '/#galeria',
  },
  {
    title: 'Contato',
    path: '/#contato',
  },
  {
    title: 'Quem somos',
    path: '/#quem-somos',
  },
];

export const mobileBreakpoint = 970;

export const s3Url = 'https://paulo-marcenaria-fotos.s3.amazonaws.com/';

export interface Image {
  cols: number;
  rows: number;
  link: string;
}

// Add with two more pictures
// { cols: 1, rows: 3, link: 'armario-branco-aberto-2.jpeg' }
export const imagesWeb: Image[] = [
  { cols: 2, rows: 3, link: 'bancada-branca-de-estudo.jpeg' },
  { cols: 1, rows: 3, link: 'armario-aberto-banco-piano.jpeg' },
  { cols: 2, rows: 3, link: 'armario-bancada-cozinha-2.jpeg' },
  { cols: 1, rows: 3, link: 'armario-branco-solteiro-2.jpeg' },
  { cols: 1, rows: 2, link: 'armario-branco-casal-2.jpeg' },
  { cols: 1, rows: 2, link: 'armario-branco-casal.jpeg' },
  { cols: 1, rows: 2, link: 'armario-branco-solteiro-3.jpeg' },
  { cols: 2, rows: 3, link: 'armario-bancada-cozinha.jpeg' },
  { cols: 1, rows: 3, link: 'armario-banheiro.jpeg' },
  { cols: 1, rows: 2, link: 'armario-branco-solteiro-4.jpeg' },
  { cols: 1, rows: 2, link: 'armario-branco-solteiro.jpeg' },
  { cols: 1, rows: 2, link: 'armario-cinza-casal-2.jpeg' },
  { cols: 1, rows: 2, link: 'armario-cinza-casal.jpeg' },
  { cols: 1, rows: 2, link: 'armario-sob-geladeira.jpeg' },
  { cols: 1, rows: 2, link: 'tri-cama.jpeg' },
  { cols: 1, rows: 3, link: 'armario-branco-aberto.jpeg' },
  { cols: 1, rows: 3, link: 'armario-cinza-cozinha.jpeg' },
  { cols: 1, rows: 3, link: 'suporte-bicicleta.jpeg' },
];

// Add with two more pictures
// { cols: 1, rows: 4, link: 'armario-branco-aberto-2.jpeg' },
export const imagesMobile: Image[] = [
  { cols: 1, rows: 3, link: 'bancada-branca-de-estudo.jpeg' },
  { cols: 1, rows: 4, link: 'armario-aberto-banco-piano.jpeg' },
  { cols: 1, rows: 2, link: 'armario-bancada-cozinha-2.jpeg' },
  { cols: 1, rows: 3, link: 'armario-branco-solteiro-2.jpeg' },
  { cols: 1, rows: 2, link: 'armario-branco-casal-2.jpeg' },
  { cols: 1, rows: 2, link: 'armario-branco-casal.jpeg' },
  { cols: 1, rows: 3, link: 'armario-branco-solteiro-3.jpeg' },
  { cols: 1, rows: 2, link: 'armario-bancada-cozinha.jpeg' },
  { cols: 1, rows: 4, link: 'armario-banheiro.jpeg' },
  { cols: 1, rows: 3, link: 'armario-branco-solteiro-4.jpeg' },
  { cols: 1, rows: 3, link: 'armario-branco-solteiro.jpeg' },
  { cols: 1, rows: 2, link: 'armario-cinza-casal-2.jpeg' },
  { cols: 1, rows: 2, link: 'armario-cinza-casal.jpeg' },
  { cols: 1, rows: 2, link: 'armario-sob-geladeira.jpeg' },
  { cols: 1, rows: 3, link: 'tri-cama.jpeg' },
  { cols: 1, rows: 3, link: 'armario-branco-aberto.jpeg' },
  { cols: 1, rows: 3, link: 'armario-cinza-cozinha.jpeg' },
  { cols: 1, rows: 3, link: 'suporte-bicicleta.jpeg' },
];
